var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        "close-on-click-modal": false,
        title: "查看采购订单",
        top: "5vh",
        width: "1000px",
        visible: _vm.showDialog,
        fullscreen: "",
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "wrapper",
        },
        [
          _c("el-alert", {
            staticStyle: {
              width: "82%",
              "margin-top": "-70px",
              "margin-left": "120px",
            },
            attrs: { closable: false, title: _vm.titleTip, type: "success" },
          }),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: "",
                "label-position": "right",
                "label-width": "90px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "供应商:", prop: "supp_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "180px" },
                      attrs: {
                        clearable: "",
                        placeholder: "请选择供应商",
                        disabled: "",
                      },
                      model: {
                        value: _vm.form.supp_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "supp_id", $$v)
                        },
                        expression: "form.supp_id",
                      },
                    },
                    _vm._l(_vm.gysList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.supp_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "经办人:", prop: "operator_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "180px" },
                      attrs: {
                        clearable: "",
                        disabled: "",
                        placeholder: "请选择经办人",
                      },
                      model: {
                        value: _vm.form.operator_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "operator_id", $$v)
                        },
                        expression: "form.operator_id",
                      },
                    },
                    _vm._l(_vm.jbrList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.user_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "入库仓库:", prop: "depot_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "180px" },
                      attrs: {
                        clearable: "",
                        disabled: "",
                        placeholder: "入库仓库",
                      },
                      model: {
                        value: _vm.form.depot_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "depot_id", $$v)
                        },
                        expression: "form.depot_id",
                      },
                    },
                    _vm._l(_vm.ckList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.depot_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "minUnit" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.minUnit,
                        callback: function ($$v) {
                          _vm.minUnit = $$v
                        },
                        expression: "minUnit",
                      },
                    },
                    [_vm._v("按小单位采购")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "remark" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "303px" },
                    attrs: { disabled: "", placeholder: "备注" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  ref: "tableSort",
                  attrs: {
                    stripe: "",
                    data: _vm.list,
                    "summary-method": _vm.getSummaries,
                    "show-summary": "",
                    height: "360px",
                  },
                },
                [
                  _c(
                    "el-table-column",
                    { attrs: { align: "center", type: "index", width: "50" } },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                "popper-class": "custom-table-checkbox",
                                trigger: "hover",
                              },
                            },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.checkList,
                                    callback: function ($$v) {
                                      _vm.checkList = $$v
                                    },
                                    expression: "checkList",
                                  },
                                },
                                _vm._l(_vm.columns, function (item, index) {
                                  return _c("el-checkbox", {
                                    key: index,
                                    attrs: { label: item.label },
                                  })
                                }),
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { slot: "reference", type: "text" },
                                  slot: "reference",
                                },
                                [
                                  _c("vab-remix-icon", {
                                    attrs: { icon: "settings-line" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _vm._l(_vm.finallyColumns, function (item, index) {
                    return _c("el-table-column", {
                      key: index,
                      attrs: {
                        label: item.label,
                        prop: item.prop,
                        width: "",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          item.prop == "goods_type"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: { disabled: "" },
                                        model: {
                                          value: row.goods_type,
                                          callback: function ($$v) {
                                            _vm.$set(row, "goods_type", $$v)
                                          },
                                          expression: "row.goods_type",
                                        },
                                      },
                                      _vm._l(
                                        _vm.typeSelect,
                                        function (typeItem, index2) {
                                          return _c("el-option", {
                                            key: index2,
                                            attrs: {
                                              label: typeItem.value,
                                              value: typeItem.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ]
                                },
                              }
                            : null,
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                ],
                2
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: {
                        disabled: "",
                        "true-label": 1,
                        "false-label": 0,
                      },
                      model: {
                        value: _vm.form.auto_update_price,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "auto_update_price", $$v)
                        },
                        expression: "form.auto_update_price",
                      },
                    },
                    [_vm._v(" 生成采购入库单时重新计算商品成本 ")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "pay" }, [
                _c(
                  "div",
                  { staticClass: "left" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { label: "金额合计:" },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100px" },
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.allSum,
                            callback: function ($$v) {
                              _vm.allSum = $$v
                            },
                            expression: "allSum",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c(
                      "el-table",
                      { attrs: { stripe: "", data: _vm.list2 } },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "付款账户",
                            prop: "account",
                            width: "auto",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "付款金额",
                            prop: "amount",
                            width: "auto",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "付款人",
                            prop: "create_name",
                            width: "auto",
                            align: "center",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handlePrint } }, [_vm._v("打印")]),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
        ],
        1
      ),
      _c("ruilang-display-dialog", {
        ref: "RuilangDisplayDialog",
        attrs: { bill_type: "CD", data_id: _vm.form.order_id, type: 1 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }