var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        "close-on-click-modal": false,
        title: "添加采购订单",
        top: "5vh",
        width: "1000px",
        fullscreen: "",
        visible: _vm.showDialog,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "wrapper" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: "",
                "label-position": "right",
                "label-width": "90px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "供应商:", prop: "supp_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "180px" },
                      attrs: {
                        clearable: "",
                        placeholder: "请选择供应商",
                        filterable: "",
                        remote: "",
                        "remote-method": _vm.remoteMethod,
                        loading: _vm.loadingS,
                      },
                      on: { change: _vm.gysChange, blur: _vm.gycBlur },
                      model: {
                        value: _vm.form.supp_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "supp_id", $$v)
                        },
                        expression: "form.supp_id",
                      },
                    },
                    _vm._l(_vm.gysList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.supp_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "经办人:", prop: "operator_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "180px" },
                      attrs: { clearable: "", placeholder: "请选择经办人" },
                      model: {
                        value: _vm.form.operator_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "operator_id", $$v)
                        },
                        expression: "form.operator_id",
                      },
                    },
                    _vm._l(_vm.jbrList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.user_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "入库仓库:", prop: "depot_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "180px" },
                      attrs: { clearable: "", placeholder: "入库仓库" },
                      model: {
                        value: _vm.form.depot_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "depot_id", $$v)
                        },
                        expression: "form.depot_id",
                      },
                    },
                    _vm._l(_vm.ckList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.depot_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { prop: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "303px" },
                    attrs: { placeholder: "使用扫码器添加商品" },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "minUnit" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.minUnit,
                        callback: function ($$v) {
                          _vm.minUnit = $$v
                        },
                        expression: "minUnit",
                      },
                    },
                    [_vm._v("按小单位采购")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "maxUnit" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.maxUnit,
                        callback: function ($$v) {
                          _vm.maxUnit = $$v
                        },
                        expression: "maxUnit",
                      },
                    },
                    [_vm._v("按大单位采购")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "remark" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "303px" },
                    attrs: { placeholder: "备注" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "tableSort",
                  attrs: {
                    stripe: "",
                    data: _vm.list,
                    "summary-method": _vm.getSummaries,
                    "show-summary": "",
                    height: "360px",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { align: "center", type: "index", width: "50" },
                  }),
                  _vm._l(_vm.finallyColumns, function (item, tableIndex) {
                    return _c("el-table-column", {
                      key: tableIndex,
                      attrs: {
                        label: item.label,
                        prop: item.prop,
                        width: item.width,
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          item.prop == "goods_type"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "el-select",
                                      {
                                        model: {
                                          value: row.goods_type,
                                          callback: function ($$v) {
                                            _vm.$set(row, "goods_type", $$v)
                                          },
                                          expression: "row.goods_type",
                                        },
                                      },
                                      _vm._l(
                                        _vm.typeSelect,
                                        function (typeItem, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: typeItem.value,
                                              value: typeItem.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ]
                                },
                              }
                            : item.prop == "goods_name"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var $index = ref.$index
                                  var row = ref.row
                                  return [
                                    _c("goods-search", {
                                      ref: "goodsSearch",
                                      refInFor: true,
                                      attrs: {
                                        "f-key": row.goods_name,
                                        "brand-id": _vm.form.brand_id,
                                        "is-brand":
                                          _vm.form.supp_id == "" ? true : false,
                                      },
                                      on: {
                                        "add-rows": function ($event) {
                                          return _vm.addRows($event, $index)
                                        },
                                        "select-goods-all": function ($event) {
                                          return _vm.selectGoods($event, row)
                                        },
                                      },
                                    }),
                                  ]
                                },
                              }
                            : item.prop == "unit_name"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "el-select",
                                      {
                                        on: {
                                          change: function ($event) {
                                            return _vm.selectUnit($event, row)
                                          },
                                        },
                                        model: {
                                          value: row.unit_id,
                                          callback: function ($$v) {
                                            _vm.$set(row, "unit_id", $$v)
                                          },
                                          expression: "row.unit_id",
                                        },
                                      },
                                      _vm._l(
                                        row.arr_unit,
                                        function (unitItem, unitIndex) {
                                          return _c("el-option", {
                                            key: unitIndex,
                                            attrs: {
                                              label: unitItem.unit_name,
                                              value: unitItem.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ]
                                },
                              }
                            : item.prop == "goods_production_date"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "180px" },
                                      attrs: {
                                        type: "date",
                                        placeholder: "选择日期",
                                        format: "yyyy 年 MM 月 dd 日",
                                        "value-format": "yyyy-MM-dd",
                                      },
                                      model: {
                                        value: row.goods_production_date,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            row,
                                            "goods_production_date",
                                            $$v
                                          )
                                        },
                                        expression: "row.goods_production_date",
                                      },
                                    }),
                                  ]
                                },
                              }
                            : item.prop == "goods_price"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-input", {
                                      on: {
                                        input: function ($event) {
                                          return _vm.priceSum($event, row)
                                        },
                                      },
                                      model: {
                                        value: row.goods_price,
                                        callback: function ($$v) {
                                          _vm.$set(row, "goods_price", $$v)
                                        },
                                        expression: "row.goods_price",
                                      },
                                    }),
                                  ]
                                },
                              }
                            : item.prop == "goods_num"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-input", {
                                      on: {
                                        input: function ($event) {
                                          return _vm.priceSum($event, row)
                                        },
                                        focus: function ($event) {
                                          return _vm.inputfocus(row)
                                        },
                                      },
                                      model: {
                                        value: row.goods_num,
                                        callback: function ($$v) {
                                          _vm.$set(row, "goods_num", $$v)
                                        },
                                        expression: "row.goods_num",
                                      },
                                    }),
                                  ]
                                },
                              }
                            : item.prop == "sum_money"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-input", {
                                      on: {
                                        input: function ($event) {
                                          return _vm.moneyChange($event, row)
                                        },
                                      },
                                      model: {
                                        value: row.sum_money,
                                        callback: function ($$v) {
                                          _vm.$set(row, "sum_money", $$v)
                                        },
                                        expression: "row.sum_money",
                                      },
                                    }),
                                  ]
                                },
                              }
                            : item.prop == "remark"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-input", {
                                      model: {
                                        value: row.remark,
                                        callback: function ($$v) {
                                          _vm.$set(row, "remark", $$v)
                                        },
                                        expression: "row.remark",
                                      },
                                    }),
                                  ]
                                },
                              }
                            : {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _vm._v(" " + _vm._s(row[item.prop]) + " "),
                                  ]
                                },
                              },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "操作",
                      fixed: "right",
                      width: "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var $index = ref.$index
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.copyRow($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 复制 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.deleteRow($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
              _c(
                "el-form-item",
                [
                  _vm.showBtn
                    ? _c(
                        "el-checkbox",
                        {
                          attrs: { "true-label": 1, "false-label": 0 },
                          model: {
                            value: _vm.form.auto_update_price,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "auto_update_price", $$v)
                            },
                            expression: "form.auto_update_price",
                          },
                        },
                        [_vm._v(" 生成采购入库单时重新计算商品成本 ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("div", { staticClass: "pay" }, [
                _c(
                  "div",
                  { staticClass: "left" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { label: "金额合计:" },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100px" },
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.allSum,
                            callback: function ($$v) {
                              _vm.allSum = $$v
                            },
                            expression: "allSum",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { position: "relative" } },
                      [
                        _vm._l(_vm.pay_info, function (payInfo, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "付款金额" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100px" },
                                    model: {
                                      value: payInfo.amount,
                                      callback: function ($$v) {
                                        _vm.$set(payInfo, "amount", $$v)
                                      },
                                      expression: "payInfo.amount",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100px" },
                                      attrs: { placeholder: "付款账户" },
                                      model: {
                                        value: payInfo.account_id,
                                        callback: function ($$v) {
                                          _vm.$set(payInfo, "account_id", $$v)
                                        },
                                        expression: "payInfo.account_id",
                                      },
                                    },
                                    _vm._l(
                                      _vm.fkzhList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            value: item.id,
                                            label: item.name,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100px" },
                                      attrs: { placeholder: "选择付款人" },
                                      model: {
                                        value: payInfo.user_id,
                                        callback: function ($$v) {
                                          _vm.$set(payInfo, "user_id", $$v)
                                        },
                                        expression: "payInfo.user_id",
                                      },
                                    },
                                    _vm._l(_vm.fkrList, function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.user_name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        _c("i", {
                          staticClass: "el-icon-circle-plus addbtn",
                          on: { click: _vm.addInput },
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "success" },
              on: {
                click: function ($event) {
                  return _vm.save(1)
                },
              },
            },
            [_vm._v("审核并生成采购单")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.save(0)
                },
              },
            },
            [_vm._v("提交")]
          ),
          _c("el-button", [_vm._v("打印")]),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }